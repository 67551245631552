<template src="./index.html" />

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import DatePicker from 'vue2-datepicker'
// eslint-disable-next-line import/extensions
import {
  BBreadcrumb, BButton, BFormFile, BCard, BCol, BContainer, BFormSelect, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import 'vue2-datepicker/locale/vi.js'
import 'vue2-datepicker/index.css'
import { VueGoodTable } from 'vue-good-table'
import '@core/scss/vue/libs/vue-good-table.scss'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import FormSemester from '@/views/admin/category/semester/FormSemester.vue'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
import { hasPermissionForResource } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'

export default {
  name: 'Semester',
  components: {
    BBreadcrumb,
    BButton,
    BFormFile,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    ValidationProvider,
    ValidationObserver,
    DatePicker,
    VueGoodTable,
    vSelect,
    FormSemester,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      loading: true,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: '',
          },
        ],
        page: 1,
        perPage: 10,
      },
      totalRecords: 0,
      items: [],
      itemsDisplay: [],
      editMode: false,
      fields: [
        {
          field: 'rowNum',
          filterOptions: {
            enabled: false,
            placeholder: 'STT',
          },
          label: 'STT',
          sortable: false,
          tdClass: 'text-center',
          thClass: 'text-center',
          width: '80px',
        },
        {
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Học kỳ',
            trigger: 'enter',
          },
          label: 'Học kỳ',
          sortable: false,
        },
        {
          field: 'semesterTypeName',
          filterOptions: {
            enabled: true,
            placeholder: 'Tất cả',
            filterDropdownItems: [

            ],
          },
          label: 'Loại học kỳ',
          sortable: false,
        },
        {
          field: 'status',
          filterOptions: {
            enabled: true,
            placeholder: 'Tất cả',
            trigger: 'enter',
            filterDropdownItems: [

            ],
          },
          label: 'Trạng thái',
          sortable: false,
        },
        {
          field: 'action',
          label: '',
          tdClass: 'text-center',
          sortable: false,
        },
      ],
      row_selected: null,
      dataSemester: {},

    }
  },
  computed: {
    ...mapGetters({
      semesters: 'semester/semesters',
      total: 'semester/total',
      semesterTypes: 'semester/semesterTypes',
      statuses: 'semester/statuses',
    }),
    rows() {
      return this.items.length
    },
    updatable() {
      return hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.SEMESTER)
    },
  },
  async created() {
    await this.getInit()
    const semesterTypesCV = this.semesterTypes.map(item => ({ value: item.id, text: item.name }))
    const statusesCV = this.statuses.map(item => ({ value: item.id, text: item.name }))
    this.$set(this.fields[2].filterOptions, 'filterDropdownItems', semesterTypesCV)
    this.$set(this.fields[3].filterOptions, 'filterDropdownItems', statusesCV)
    await this.getData(this.serverParams.page, this.serverParams.perPage)
  },
  methods: {
    ...mapActions({
      getSemesters: 'semester/getSemesters',
      getInit: 'semester/getInit',
      createSemester: 'semester/createSemester',
      deleteSemester: 'semester/deleteSemester',

    }),
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },
    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      })
      this.loadItems()
    },
    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },
    // load items is what brings back the rows from server
    loadItems() {
      this.btnSearchClick()
    },

    showToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },

    async editItem(item) {
      this.row_selected = item
      await this.$refs.DetaiTitlePopupRef.showModal(item)
    },

    async deleteItem(item) {
      this.$swal
        .fire({
          title: 'Xóa bản ghi này?',
          text: '',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Xóa',
          cancelButtonText: 'Hủy',
        })
        .then(async result => {
          if (result.isConfirmed) {
            const result = await this.deleteSemester(item.id)
            if (result) {
              await this.getData(this.serverParams.page, this.serverParams.perPage)
              this.showToast('Đã xóa thành công!', 'CheckIcon', 'success', '')
            } else {
              this.showToast('Có lỗi xẩy ra', 'XCircleIcon', 'warning', '')
            }
          }
        })
    },

    async btnSearchClick() {
      // const titleItems = ref([])
      // await store.dispatch('title/getTitleItems', {
      //   currentPage: 1,
      //   itemsPerPage: 5,
      //   status: 1,
      //   sort: '1_desc'
      // })
      // titleItems.value = store.getters['title/titleItems']

      this.loading = true
      await this.getData(this.serverParams.page, this.serverParams.perPage)
    },

    openModal() {
      this.dataSemester = {}
      this.$refs['modal-semester'].show()
    },

    editSemester(data) {
      this.dataSemester = data
      this.$refs['modal-semester'].show()
    },

    async saveDataSemester(data) {
      const dataSend = {
        id: data.id,
        name: data.name,
        orderNo: data.orderNo,
        status: data.status,
        semesterTypeId: data.semesterTypeId,
      }
      const temp = await this.createSemester(dataSend)
      if (temp) {
        this.$refs['modal-semester'].hide()
        await this.getData(this.serverParams.page, this.serverParams.perPage)
      }
    },

    getStatusName(id) {
      return this.statuses.find(status => status.id === id).name
    },

    async getData(page, perPage) {
      this.items = []
      this.itemsDisplay = []
      await this.getSemesters({
        currentPage: page,
        itemsPerPage: perPage,
        semesterTypeId: 1,
        status: 1,
        sort: '1_asc',
      })
      this.items = this.semesters
      this.totalRecords = this.total
      this.itemsDisplay = [...this.items]
    },
  },
}
</script>
<style>
.v-select.drop-up.vs--open .vs__dropdown-toggle {
  border-radius: 0 0 4px 4px;
  border-top-color: transparent;
  border-bottom-color: rgba(60, 60, 60, 0.26);
}

[data-popper-placement="top"] {
  border-radius: 4px 4px 0 0;
  border-top-style: solid;
  border-bottom-style: none;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
